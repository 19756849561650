import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

interface Props {
  src: string;
  alt?: string;
  style?: any;
}

function Image({ src, alt = '', style = {} }: Props) {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            extension
            publicURL
            relativePath
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const image = data.images.edges.find(
    (img: any) => img.node.relativePath === src
  );

  if (image.node.extension === 'svg') {
    return <img src={image.node.publicURL} alt={alt} style={style} />;
  }

  return (
    <Img fluid={image.node.childImageSharp.fluid} alt={alt} style={style} />
  );
}

export default Image;

import React, { useState } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import { mainMenu } from '../../utils/menus';
import Image from '../image';

const brand = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {},
  },
};

const menu = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {},
  },
};

interface Props {
  location: string;
}

export function MainMenu({ location }: Props) {
  const [isActive, setisActive] = useState(false);

  const menuItems = mainMenu.template.map(item => {
    if (item.hasOwnProperty('submenu')) {
      return (
        <div key={item.id} className="navbar-item has-dropdown is-hoverable">
          <Link
            className="navbar-link is-arrowless has-text-weight-bold"
            to={`${item.link === '' ? location : item.link}`}
          >
            {item.label.toUpperCase()}
          </Link>

          <div className="navbar-dropdown">
            {item.submenu?.map(dropdownItem => {
              if (dropdownItem.label === 'separator') {
                return <hr key={dropdownItem.id} className="navbar-divider" />;
              }
              return (
                <Link
                  key={dropdownItem.id}
                  className="navbar-item"
                  to={`${dropdownItem.link}`}
                >
                  {dropdownItem.label}
                </Link>
              );
            })}
          </div>
        </div>
      );
    } else {
      return (
        <Link key={item.id} className="navbar-item has-text-weight-bold" to={`${item.link}`}>
          {item.label.toUpperCase()}
        </Link>
      );
    }
  });

  return (
    <nav
      className={`navbar is-transparent is-fixed-top${
        isActive ? ' is-active' : ''
      }`}
      role="navigation"
      aria-label="main navigation"
    >
      <motion.div
        className="navbar-brand"
        variants={brand}
        initial="hidden"
        animate="visible"
      >
        <Link className="navbar-item" to="/">
          <Image src="HPSLogo.svg" />
        </Link>

        <Link
          to="/"
          role="button"
          className={`navbar-burger burger${isActive ? ' is-active' : ''}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={() => {
            setisActive(!isActive);
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Link>
      </motion.div>

      <motion.div
        id="mainMenu"
        className={`navbar-menu${isActive ? ' is-active' : ''}`}
        variants={menu}
        initial="hidden"
        animate="visible"
      >
        {menuItems}

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-light" to="/">
                Prijava
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </nav>
  );
}

/*
function NavBar() {
  return (
    <nav
      className="navbar is-transparent is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <motion.div
        className="navbar-brand"
        variants={brand}
        initial="hidden"
        animate="visible"
      >
        <Link className="navbar-item" to="/">
          <Image src="HPSLogo.svg" />
        </Link>

        <Link
          to="/"
          role="button"
          className="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </Link>
      </motion.div>

      <motion.div
        id="navbarBasicExample"
        className="navbar-menu"
        variants={menu}
        initial="hidden"
        animate="visible"
      >
        <div className="navbar-start">
          <Link className="navbar-item" to="/">
            Home
          </Link>

          <Link className="navbar-item" to="/">
            Documentation
          </Link>

          <div className="navbar-item has-dropdown is-hoverable">
            <Link className="navbar-link" to="/">
              More
            </Link>

            <div className="navbar-dropdown">
              <Link className="navbar-item" to="/">
                About
              </Link>
              <Link className="navbar-item" to="/">
                Jobs
              </Link>
              <Link className="navbar-item" to="/">
                Contact
              </Link>
              <hr className="navbar-divider" />
              <Link className="navbar-item" to="/">
                Report an issue
              </Link>
            </div>
          </div>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <Link className="button is-primary" to="/">
                <strong>Sign up</strong>
              </Link>
              <Link className="button is-light" to="/">
                Log in
              </Link>
            </div>
          </div>
        </div>
      </motion.div>
    </nav>
  );
}

export default NavBar;
*/

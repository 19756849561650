export const mainMenu = {
  name: 'main',
  template: [
    {
      id: 'main10s00s00',
      label: 'Vijesti',
      link: '/',
      icon: '',
    },
    {
      id: 'main20s00s00',
      label: 'Kalendar',
      link: '/',
      icon: '',
    },
    {
      id: 'main30s00s00',
      label: 'Plivači',
      link: '/',
      icon: '',
    },
    {
      id: 'main40s00s00',
      label: 'Rezultati',
      link: '',
      icon: '',
      submenu: [
        {
          id: 'main40s05s00',
          label: 'Poredak',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s10s00',
          label: 'Zapisnici',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s15s00',
          label: 'Rekordi',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s20s00',
          label: 'Norme',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s25s00',
          label: 'Kvalificirani za PH',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s30s00',
          label: 'separator',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s35s00',
          label: 'LENED',
          link: '/',
          icon: '',
        },
        {
          id: 'main40s40s00',
          label: 'Live',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main50s00s00',
      label: 'Programi',
      link: '',
      icon: '',
      submenu: [
        {
          id: 'main50s05s00',
          label: 'HPS Grand prix',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s10s00',
          label: 'HPS Mini grand prix',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s15s00',
          label: 'separator',
          link: '',
          icon: '',
        },
        {
          id: 'main50s20s00',
          label: 'Lista uspješnosti klubova',
          link: '/',
          icon: '',
        },
        {
          id: 'main50s25s00',
          label: 'Lista uspješnosti plivača',
          link: '/',
          icon: '',
        },
      ],
    },
    {
      id: 'main60s00s00',
      label: 'Razno',
      link: '',
      icon: '',
      submenu: [
        {
          id: 'main60s05s00',
          label: 'Hrvatski plivački savez',
          enabled: false,
        },
        {
          id: 'main60s10s00',
          label: 'Uprava',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s15s00',
          label: 'Klubovi',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s20s00',
          label: 'Povijest',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s25s00',
          label: 'Kontakt',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s30s00',
          label: 'separator',
          link: '',
          icon: '',
        },
        {
          id: 'main60s35s00',
          label: 'Dokumenti',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s40s00',
          label: 'Sponzori',
          link: '/',
          icon: '',
        },
        {
          id: 'main60s45s00',
          label: 'Poveznice',
          link: '/',
          icon: '',
        },
      ],
    },
  ],
};

import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from '@popmotion/popcorn';
import {
  FaNewspaper,
  FaSwimmer,
  FaListAlt,
  FaCalendarAlt,
} from 'react-icons/fa';

import Image from '../image';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    };
  },
};

const container = {
  enter: { scale: 0 },
  center: { scale: 1 },
  exit: { scale: 0 },
};

const images = [
  <motion.div className="carousel-container" variants={container}>
    <p className="title is-2" style={{ marginBottom: '1.5rem' }}>Naši korisnici pretražuju:</p>
    <div
      className="carousel-link"
      style={{ display: 'flex', maxWidth: '30rem' }}
    >
      <div style={{ flex: 1, marginRight: '3px', textAlign: 'center' }}>
        <Link to="/">
          <FaNewspaper style={{ fontSize: '2.5rem' }} />
          <p>VIJESTI</p>
        </Link>
      </div>
      <div style={{ flex: 1, marginRight: '3px', textAlign: 'center' }}>
        <Link to="/">
          <FaSwimmer style={{ fontSize: '2.5rem' }} />
          <p>PLIVAČI</p>
        </Link>
      </div>
      <div style={{ flex: 1, marginRight: '3px', textAlign: 'center' }}>
        <Link to="/">
          <FaListAlt style={{ fontSize: '2.5rem' }} />
          <p>PH</p>
        </Link>
      </div>
      <div style={{ flex: 1, textAlign: 'center' }}>
        <Link to="/">
          <FaCalendarAlt style={{ fontSize: '2.5rem' }} />
          <p>KALENDAR</p>
        </Link>
      </div>
    </div>
  </motion.div>,
  <motion.div className="carousel-container" variants={container}>
    <p className="title is-2" style={{ marginBottom: '1.5rem' }}>HPS Grand Prix</p>
    <div className="carousel-link">
      <Link to="vijesti">
        <Image
          src="HPSGP.png"
          alt="HPSGP"
          style={{
            width: '100px',
            filter: 'drop-shadow(0px 0px 9px white)',
          }}
        />
      </Link>
    </div>
  </motion.div>,
  <motion.div className="carousel-container" variants={container}>
    <p className="title is-2" style={{ marginBottom: '1.5rem' }}>
      Official Sponsor of the Croatian Swimming Federation
    </p>
    <div className="carousel-link">
      <a
        href="https://www.arenawaterinstinct.com/en_global/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src="ArenaLogoBijeli.png"
          alt="ArenaLogoBijeli"
          style={{
            width: '100px',
          }}
        />
      </a>
    </div>
  </motion.div>,
  <motion.div className="carousel-container" variants={container}>
    <p className="title is-2" style={{ marginBottom: '1.5rem' }}>
      Službeni sponzor Hrvatskog plivačkog saveza
    </p>
    <div className="carousel-link">
      <a href="https://www.hep.hr/" target="_blank" rel="noopener noreferrer">
        <Image
          src="HEPLogo.png"
          alt="HEPLogo"
          style={{
            width: '150px',
            filter: 'drop-shadow(0px 0px 9px white)',
          }}
        />
      </a>
    </div>
  </motion.div>,
  <motion.div className="carousel-container" variants={container}>
    <p className="title is-2" style={{ marginBottom: '1.5rem' }}>
      Službeni sponzor Hrvatskog plivačkog saveza
    </p>
    <div className="carousel-link">
      <a
        href="http://www.janaf.hr/naslovnica/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Image
          src="JanafLogo.png"
          alt="JanafLogo"
          style={{
            width: '200px',
            filter: 'drop-shadow(0px 0px 9px white)',
          }}
        />
      </a>
    </div>
  </motion.div>,
];

function Carousel() {
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // setPage(oldPage => oldPage + 1);
      // setDirection(1);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  // We only have 3 images, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-2 to find our image ID in the array below. By passing an
  // absolute page index as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new image. So you can infinitely paginate as few as 1 images.
  const imageIndex = wrap(0, images.length, page);

  const paginate = (newDirection: number) => {
    setPage(page + newDirection);
    setDirection(newDirection);
  };

  return (
    <>
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          className="carousel-motion"
          key={page}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 500, damping: 200 },
            opacity: { duration: 0.2 },
            delayChildren: 0.3,
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);

            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
        >
          {images[imageIndex]}
        </motion.div>
      </AnimatePresence>
      <div className="next" onClick={() => paginate(1)}>
        {'‣'}
      </div>
      <div className="prev" onClick={() => paginate(-1)}>
        {'‣'}
      </div>
    </>
  );
}

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
const swipeConfidenceThreshold = 10000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

export default Carousel;
